* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.notification-block{
  background-color: #333;
  font-size: .9rem;
}

@media(min-width: 600px){
  .notification-block{
    top: 4.5rem;
  }
  
}
