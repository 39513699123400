.mg-section {
  background-color: #1a1a1a;
}

.cs-section {
  background-color: #fff;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
  background: #f5f5f7;
  width: 100%;
}

.section-image-bottom {
  padding: 5rem 2rem;
}

.section-image-bottom img {
  width: 100%;
  margin-top: 1rem;
  max-width: 720px;
}

@media (max-width:1080px) {
  .home-grid {
    grid-template-columns: 100%;
    gap: 1rem;
    padding: 1rem 0;
  }

  .home-grid section {
    grid-column: 1 !important;
    border-radius: 0;
  }
}

/* International Events Logos - Animation */
.ie-scroll-container {
  width: 100%;
  overflow: hidden;
}

.ie-image-wrapper {
  display: flex;
  align-items: center;
  width: max-content;
  animation: scroll 50s linear infinite;
}

@keyframes scroll {
  0%, 100% {
      transform: translateX(0%);
  }
  100% {
    /* Image width + margin * number of images */
      transform: translateX(-3740px);
  }
}

.ie-a {
  margin-right: 60px;
  opacity: .8;
  transition: opacity .2s ease;
}

.ie-a:hover {
  opacity: 1;

}
.ie-a img {
  width: 120px;
  height: auto;
  filter: invert(1);
}

/* End of Jewelry Shows Logos - Animation */