.footernav{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  padding: 1.5rem 0;
  border-color: #444;
  border-style: solid;
  border-width: 1px 0;
}

.footernav p,
.footernav-mobile p {
  font-size: 12px !important;
  cursor: default !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  color: #fff !important;
  text-align: left;
}
.footernav p {
  margin-bottom: 8px !important;
}

.footer-link,
.footernav-mobile a {
  list-style-type: none;
  padding: 4px 0;
  display: flex;
  color: #bbb;
  font-size: 14px;
  font-weight: 400;
  transition: color .2s ease;
  text-align: left;
}
.footer-link:hover,
.footernav-mobile a:hover {
  color: #fff;
  text-decoration: none;
}

.footer-icon {
  min-width: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .7;
  transition: all .2s ease !important;
}

.footer-icon:hover {
  opacity: 1;
}