:root {
  --mainColor: #28f;
  --mainColorLight: #3af;
}

.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--mainColorLight);
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: super;
  font-size: 40%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: none !important;
}

img {
  user-select: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* containers, sections and section-wrapper */

section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

section section {
  max-width: 1080px;
  padding: 6rem 2rem;
}

@media (max-width:767px) {
  section section {
    padding: 4rem 2rem;
  }
}

/* Buttons in a row getting column when responsive */

.button-group {
  margin-top: 1rem;
}

.button-group>button, .button-group>a {
  margin: 8px;
}

/* Headroom Styles */

.headroom {
  top: auto;
  left: 0;
  right: 0;
  z-index: 100;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

/* End of Headroom Styles */

/* Carousel Styles */

.carousel .control-next.control-arrow:before, .control-prev.control-arrow:before {
  border: solid #fff !important;
  border-width: 0 3px 3px 0 !important;
  padding: 6px;
  transform: rotate(-45deg);
  margin: 4px !important;
}

.carousel .control-prev.control-arrow:before {
  transform: rotate(135deg);
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

.carousel .control-dots .dot {
  background: #bbb !important;
  box-shadow: none !important;
}

.carousel .control-dots .dot.selected {
  background: var(--mainColor) !important;
}

.carousel .slide {
  background: transparent;
}

/* End of Carousel Styles */

/* Start of React Player Play Button Styles */

.react-player__shadow {
  background: #000a !important;
  transition: all .2s ease-in-out;
}

.react-player__shadow:hover {
  background: #000d !important;
  transform: scale(1.2);
}

/* End of React Player Play Button Styles */

.lazyload-wrapper {
  max-width: 100%;
  width: stretch;
}


.ElementsApp input, .CardField-number input{
  color: #fff !important;
}

.InputContainer .InputElement{
  color: #fff !important;
}
