.nav {
  width: 100%;
  height: 72px;
  background-color: #111;
  display: flex;
  justify-content: center;
}

.navbar {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  display: flex;
  height: 100%;
  opacity: 1;
  transition: opacity .2s ease;
}
.navbar-logo:hover {
  opacity: .7;
}

.nav-btn {
  opacity: 1 !important;
  color: #fff !important;
  padding-inline: 1rem !important;
  background: transparent !important;
  height: 100%;
  font-size: .8rem !important;
  letter-spacing: .4px;
  text-transform: uppercase !important;
  border-radius: 8px!important;
  transition: all .2s ease !important;
}

.dropdown-arrow{
  opacity: .7;
  transition: transform .2s ease !important;
}

.nav-btn-login {
  padding-inline: .75rem !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}
.nav-btn:hover, .nav-btn-login:hover, .nav-dropdown-selected{
  opacity: .7 !important;
}

.nav-selected {
  opacity: .7 !important;
}

.nav-dropdown {
  color: #fff !important;
  border-radius: 8px !important;
  justify-content: left !important;
  background-color: transparent !important;
  transition: background-color .1s ease !important;
  padding-inline: 1rem !important;
  height: 40px !important;
  font-size: .875rem !important;
}

.nav-dropdown:hover {
  background-color: #444 !important;
}